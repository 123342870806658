export const oneOfAKind = {
  'camp67-one-of-a-kind.premium.prefix':
    'A premium dormitory & private cottage provider from',
  'camp67-one-of-a-kind.team-behind': 'from the team behind',
  'camp67-one-of-a-kind.quality.hygienic.title': 'Hygienic',
  'camp67-one-of-a-kind.quality.hygienic.image-alt':
    'Globally renowned Sanskriti singer, special guest Gabriella Burnel from France seated on the Gaushala grounds.',
  'camp67-one-of-a-kind.quality.hygienic.text':
    'Upholding high standards for your comfort, at an international level.',
  'camp67-one-of-a-kind.quality.safe-and-secure.title': 'Safe & Secure',
  'camp67-one-of-a-kind.quality.safe-and-secure.image-alt':
    'The tall and secure gates of Kumbh Sukrit camp 2019.',
  'camp67-one-of-a-kind.quality.safe-and-secure.text':
    'A stay of choice for celebrities and public personas from everywhere.',
  'camp67-one-of-a-kind.quality.peaceful.title': 'Peaceful',
  'camp67-one-of-a-kind.quality.peaceful.image-alt':
    'The peaceful forest-like enclosure of Kumbh Sukrit camp with tall trees, clean pathways and a beautiful environment.',
  'camp67-one-of-a-kind.quality.peaceful.text':
    'Nestled in the <i>Sanskriti Vann</i>, where Rishis have meditated through time.',
  'camp67-one-of-a-kind.graphic.2013': '500+ attendees, 10+ countries',
  'camp67-one-of-a-kind.graphic.2019': '10,000 attendees, 26 countrie',
  'camp67-one-of-a-kind.graphic.2025.description': 'booked so far',
  'camp67-one-of-a-kind.graphic.2025.book-now': 'book now!',
  'camp67-one-of-a-kind.calendar.paush-purnima.date': '13 Jan 2025',
  'camp67-one-of-a-kind.calendar.paush-purnima.title': 'Paush Purnima',
  'camp67-one-of-a-kind.calendar.paush-purnima.description':
    'Day of the Moon God, Lord Chandra; Kumbh begins.',
  'camp67-one-of-a-kind.calendar.makar-sankranti.date': '14 Jan 2025',
  'camp67-one-of-a-kind.calendar.makar-sankranti.title': 'Makar Sankranti',
  'camp67-one-of-a-kind.calendar.makar-sankranti.description':
    'The turning of seasons, and beginning anew.',
  'camp67-one-of-a-kind.calendar.mauni-amavasya.date': '29 Jan 2025',
  'camp67-one-of-a-kind.calendar.mauni-amavasya.title': 'Mauni Amavasya',
  'camp67-one-of-a-kind.calendar.mauni-amavasya.description':
    'Silence & reflection, amongst millions.',
  'camp67-one-of-a-kind.calendar.basant-panchami.date': '3 Feb 2025',
  'camp67-one-of-a-kind.calendar.basant-panchami.title': 'Basant Panchami',
  'camp67-one-of-a-kind.calendar.basant-panchami.description':
    'Spring arrives; dedicated to Goddess Saraswati.',
  'camp67-one-of-a-kind.calendar.achal-saptami.date': '4 Feb 2025',
  'camp67-one-of-a-kind.calendar.achal-saptami.title': 'Achal Saptami',
  'camp67-one-of-a-kind.calendar.achal-saptami.description':
    'A day dedicated to the Sun God, Lord Surya.',
  'camp67-one-of-a-kind.calendar.maghi-purnima.date': '12 Feb 2025',
  'camp67-one-of-a-kind.calendar.maghi-purnima.title': 'Maghi Purnima',
  'camp67-one-of-a-kind.calendar.maghi-purnima.description':
    'A day of charity, and giving to the needy.',
  'camp67-one-of-a-kind.calendar.maha-shivratri.date': '26 Feb 2025',
  'camp67-one-of-a-kind.calendar.maha-shivratri.title': 'Maha Shivratri',
  'camp67-one-of-a-kind.calendar.maha-shivratri.description':
    'Dedicated to Lord Shiva; the last day of Kumbh.',
};

export const weLoveKumbh = {
  'camp67-we-love-kumbh.decade-old': 'A decade-old story',
  'camp67-we-love-kumbh.we-know-and': 'We know and',
  'camp67-we-love-kumbh.love': 'love',
  'camp67-we-love-kumbh.rto-kumbh-hosts':
    'As a registered tour operator and together with Incredible India, we have been hosting Kumbh for 10+ years.',
  'camp67-we-love-kumbh.moments-kumbh-2019': 'Moments from Kumbh 2019',
  'camp67-we-love-kumbh.hear-from-visitors': 'Hear from our international visitors',
  'camp67-we-love-kumbh.richard-testimonial.location': 'New York, USA',
  'camp67-we-love-kumbh.richard-testimonial.image-alt':
    'Richard Singh from New York USA, at our campsite in 2019.',
  'camp67-we-love-kumbh.richard-testimonial.quote':
    'The camp is fantastic. The staff were so amazing and accommodating. Everyone became my friend here.',
  'camp67-we-love-kumbh.rajesh-testimonial.location': 'Senior VP of Purchasing, Volvo',
  'camp67-we-love-kumbh.rajesh-testimonial.image-alt':
    'Senior VP of Purchasing at Volvo, Rajesh Mittal at our campsite in 2019.',
  'camp67-we-love-kumbh.rajesh-testimonial.quote':
    'Such a peaceful and a holy experience. We really enjoyed our stay and we would also like to come back',
  'camp67-we-love-kumbh.gabriella-testimonial.location':
    'Sanskrit Scholar, @gaieasanskrit',
  'camp67-we-love-kumbh.gabriella-testimonial.image-alt':
    'Sanskrit Scholar Gabriella Burnel at our campsite in 2019.',
  'camp67-we-love-kumbh.gabriella-testimonial.quote':
    'One thing I learnt in this place is: there is no need to wish for anything. It will happen in its natural flow.',
  'camp67-we-love-kumbh.pawars-testimonial.location': 'Microsoft & Edukinect',
  'camp67-we-love-kumbh.pawars-testimonial.image-alt':
    'Amit and Swapna Pawar of Microsoft and Edukinect at our campsite in 2019.',
  'camp67-we-love-kumbh.pawars-testimonial.quote':
    'Extremely beautiful, very comfortable. The service from everybody has been very good.',
};

export const ourLocation = {
  'camp67-our-location.ganges-banks': 'On the Ganges banks',
  'camp67-our-location.lay-tents':
    'lay the tents of sixty-seven <br className="hidden sm:block" /> standing strong and radiant',
  'camp67-our-location.ganges-banks-location':
    'Located right on the banks of the Ganges, you will not miss any Kumbh events, and yet, have your own, private enclosure away from the hustle and bustle.',
};

export const tents = {
  'camp67-accommodation.twin-room.title': 'Twin Bed Cottage',
  'camp67-accommodation.twin-room.image-alt':
    'A room with two single beds and a comfortable seating arrangement.',
  'camp67-accommodation.twin-room.subtitle': 'For two.',
  'camp67-accommodation.twin-room.description':
    'A cozy suite with two single beds. Perfect for couples, friends or siblings.',
  'camp67-accommodation.twin-room.guests': '2 guests',
  'camp67-accommodation.family-room.title': 'Family Cottage',
  'camp67-accommodation.family-room.image-alt':
    'A spacious double room with a queen with attached bunk, and separate single bed.',
  'camp67-accommodation.family-room.subtitle': 'For families of four.',
  'camp67-accommodation.family-room.description':
    'A spacious suite hosting a queen bed attached with a bunk, and a single. Ideal for small families.',
  'camp67-accommodation.family-room.guests': '4 guests',
  'camp67-accommodation.family-room-plus.title': 'Family Cottage Plus',
  'camp67-accommodation.family-room-plus.image-alt':
    'A spacious double room with a queen with attached bunk, and separate bunk bed.',
  'camp67-accommodation.family-room-plus.subtitle': 'For families of six.',
  'camp67-accommodation.family-room-plus.description':
    'A large suite with 2 queen beds, each attached with a bunk. Best for larger families.',
  'camp67-accommodation.family-room-plus.guests': '6 guests',
  'camp67-accommodation.dorm-single.title': 'Dormitory (Single)',
  'camp67-accommodation.dorm-single.image-alt':
    'A budget-friendly, relaxing single-bed dorm room. No walls will be in the actual facility.',
  'camp67-accommodation.dorm-single.subtitle': 'For one, dorm style.',
  'camp67-accommodation.dorm-single.description':
    'A communal suite with 1 single bed amongst 8 other single beds in a large dormitory.',
  'camp67-accommodation.dorm-single.guests': '1 guest',
  'camp67-accommodation.dorm-bunk.title': 'Dormitory (Bunk)',
  'camp67-accommodation.dorm-bunk.image-alt':
    'A budget-friendly, relaxing bunk-bed dorm room. No walls will be in the actual facility.',
  'camp67-accommodation.dorm-bunk.subtitle': 'For two, dorm style.',
  'camp67-accommodation.dorm-bunk.description':
    'A communal suite with 1 bunk bed amongst 8 other bunk beds in a large dormitory.',
  'camp67-accommodation.dorm-bunk.guests': '2 guests',
  'camp67-accommodation.price-per-guest': 'per guest',
  'camp67-accommodation.price-meals-included': 'Meals included',
  'camp67-accommodation.price-bathroom-included': 'Private bathroom & toilet',
  'camp67-accommodation.price-shared-bathroom-included': 'Attached bathrooms & toilets',
  'camp67-accommodation.price-bathing-dates': 'on bathing dates',
  'camp67-accommodation.view-next': 'View Next >',
  'camp67-accommodation.choose-accommodation': 'Choose Accommodation',
  'camp67-accommodation.call-to-learn-more':
    'Call or WhatsApp +918860092457 / +61478947432 to learn more.',
};

export const accommodationAndAmenities = {
  'camp67-accommodation-and-amenities.amenities-included': 'All Amenities Included',
  'camp67-accommodation-and-amenities.come-to-home':
    'Come to your home <br /> away from home',
  'camp67-accommodation-and-amenities.choose-from-packages':
    'Choose from our accommodation packages, suitable for couples, families, and groups (small and large).',
  'camp67-accommodation-and-amenities.luxurious-title': 'Luxurious Tents',
  'camp67-accommodation-and-amenities.luxurious-detail':
    'Deluxe Cottage Tents for 2, 3, 4 or 6 occupancy, or tent dormitories with single and bunk beds.',
  'camp67-accommodation-and-amenities.all-in-one-city-title': 'All-in-one tent city',
  'camp67-accommodation-and-amenities.all-in-one-city-detail':
    'Common areas for each private cottage or dormitory, 24/7 service from our trained staff, and a central café.',
  'camp67-accommodation-and-amenities.heater-services-title': 'Heater Services',
  'camp67-accommodation-and-amenities.heater-services-detail':
    'Heating solutions available, ensuring a warm and comfortable environment regardless of the outside temperature.',
  'camp67-accommodation-and-amenities.cafe-central-title': '24/7 Cafe Central',
  'camp67-accommodation-and-amenities.cafe-central-detail':
    'Round-the-clock café offering a selection of coffees, teas, and food options.',
  'camp67-accommodation-and-amenities.daily-housekeeping-title': 'Daily Housekeeping',
  'camp67-accommodation-and-amenities.daily-housekeeping-detail':
    'Cleaning services provided daily to ensure your space is spotless and inviting, with special attention to detail.',
  'camp67-accommodation-and-amenities.bottled-water-title': 'Bottled Water',
  'camp67-accommodation-and-amenities.bottled-water-detail':
    'Complimentary premium bottled water, refreshed daily for your hydration and comfort.',
  'camp67-accommodation-and-amenities.natural-gardens-title': 'Natural Gardens',
  'camp67-accommodation-and-amenities.natural-gardens-detail':
    'Expansive gardens featuring indigenous plants and flowers, offering serene spots for relaxation and reflection.',
  'camp67-accommodation-and-amenities.ayurvedic-products-title': 'Ayurvedic Products',
  'camp67-accommodation-and-amenities.ayurvedic-products-detail':
    'Exclusive range of natural Ayurvedic skincare products, provided for your wellness and rejuvenation.',
  'camp67-accommodation-and-amenities.minutes-from-ganges-title':
    'Minutes from the Ganges',
  'camp67-accommodation-and-amenities.minutes-from-ganges-detail':
    'Strategically located just a 5 minute walk from the sacred Ganges River, offering easy access to its tranquil waters and surrounding areas.',
  'camp67-accommodation-and-amenities.yoga-and-meditation-title':
    'Yoga & Meditation Sessions',
  'camp67-accommodation-and-amenities.yoga-and-meditation-detail':
    'Participate in Ancient Indian practices. Included in our accommodation package prices.',
  'camp67-accommodation-and-amenities.private.title': 'Deluxe Cottage Tent Accommodation',
  'camp67-accommodation-and-amenities.dorm.title': 'Dormitory Tent Accommodation',
};

export const tentAmenities = {
  'camp67-tent-amenity.comfortable-living': 'Comfortable Living',
  'camp67-tent-amenity.comfortable-living.single-beds':
    'Choice of a set of single beds or one bunk bed',
  'camp67-tent-amenity.comfortable-living.single-bed': 'Single bed',
  'camp67-tent-amenity.comfortable-living.bunk-bed': 'Bunk bed',
  'camp67-tent-amenity.comfortable-living.queen-bed':
    'Queen and joint bunk bed, with separate single bed',
  'camp67-tent-amenity.comfortable-living.two-queen-beds':
    'Two queen with joint bunk beds',
  'camp67-tent-amenity.comfortable-living.quilt-blanket-pillows':
    'Quilt/Blanket and Pillows',
  'camp67-tent-amenity.comfortable-living.side-table-per-bed': 'Side table per bed',
  'camp67-tent-amenity.comfortable-living.centre-table': 'Centre table',
  'camp67-tent-amenity.comfortable-living.sofa-chairs': '2x Sofa Chairs',
  'camp67-tent-amenity.comfortable-living.side-table-kettle':
    'Side table with hot water kettle, disposable glasses',
  'camp67-tent-amenity.comfortable-living.table-lamp': 'Table lamp',
  'camp67-tent-amenity.toiletries': 'Toiletries',
  'camp67-tent-amenity.toiletries.private-bathroom':
    'Private, attached toilet and bathroom facility',
  'camp67-tent-amenity.toiletries.shared-toilets-dorm':
    'Shared toilets & bathrooms (shared across dormitory)',
  'camp67-tent-amenity.toiletries.bath-towel': 'Bath towel per person',
  'camp67-tent-amenity.toiletries.sukrit-soaps-lotion':
    'Complimentary Sukrit Ayurveda soaps and lotion',
  'camp67-tent-amenity.toiletries.small-dustbin': 'Small dustbin',
  'camp67-tent-amenity.power-storage': 'Power and Storage',
  'camp67-tent-amenity.power-storage.electric-power-point':
    'Electric power point w/ multiple slots',
  'camp67-tent-amenity.power-storage.electric-power-point-2x':
    '2x Electric power point w/ multiple slots',
  'camp67-tent-amenity.power-storage.electric-power-point-4-sets':
    '4 sets of electric power point w/ multiple slots (2 sets per section)',
  'camp67-tent-amenity.power-storage.luggage-storage-racks': 'Luggage storage racks',
  'camp67-tent-amenity.food-beverage': 'Food & Beverage',
  'camp67-tent-amenity.food-beverage.drinking-water':
    'Drinking water bottle (1 x 500ml per person)',
  'camp67-tent-amenity.food-beverage.tea-biscuits':
    'Tea sachets and biscuits available for purchase',
  'camp67-tent-amenity.food-beverage.lemongrass-tea-biscuits':
    'Sukrit Lemongrass Tea & Biscuits',
  'camp67-tent-amenity.food-beverage.complimentary-meals':
    '<strong>Complimentary</strong> Breakfast, lunch and dinner (buffet served in the dining hall)',
  'camp67-tent-amenity.complimentary': 'Complimentary',
  'camp67-tent-amenity.complimentary.souvenir':
    'Souvenir - a baseball cap with camp67 branding, per person',
};

export const faq = {
  'camp67-faq.private-suites': 'Do you offer private suites?',
  'camp67-faq.private-suites.answer': `<p>Yes!</p><paragraph>We offer 3 private cottage options at camp67 - the <twin-room-link>Twin Bed Cottage</twin-room-link>, <family-room-link>Family Cottage</family-room-link>, or <family-room-plus-link>Family Cottage Plus</family-room-plus-link> options (2, 4, 6 occupancy). Our other 2 options are part of a larger tent dormitory, as single or bunk beds.</paragraph><paragraph>Let us know if you have any questions through the inquiry form on the right, including whether you would prefer to stay in an even more secluded, private space. For this, we can connect you with our other offerings at <kumbh-sukrit-link>Kumbh Sukrit Camp</kumbh-sukrit-link>.</paragraph>`,
  'camp67-faq.camp-start': 'When does your camp start?',
  'camp67-faq.camp-start.answer': `<p>We will be accepting bookings from the 13th of January onwards. We will let you know if plan to start sooner than this date. <camp-start-link>Subscribe below</camp-start-link> for such updates.</p>`,
  'camp67-faq.international-travel': 'Are you suited for international travellers?',
  'camp67-faq.tours-packages': 'Do you offer any tours and packages?',
  'camp67-faq.kumbh-sukrit': 'What is your relationship with Kumbh Sukrit Camp?',
  'camp67-faq.all-day-facilities': 'Do you have all-day facilities?',
  'camp67-faq.international-travel.answer':
    "<p> Yes! As mentioned <international-travel-link>above</international-travel-link>, we hosted travellers from 26 countries in the 2019 edition of Kumbh. Whether it comes to flight bookings, transportation when you reach India, or local tours which you can avail whilst you're here, we've got you covered.</p> <paragraph>If you're interested in having a chat with us, feel free to reach out to us via email, WhatsApp, or live chat below. The inquiry form on the right is also a fine place to start!</paragraph>",
  'camp67-faq.tours-packages.answer':
    "We do! Please get in touch with us via our inquiry form on the right, or our provided email, phone number, WhatsApp, or live chat. We'd love to understand what you're after, so that we can cater to you most effectively.",
  'camp67-faq.kumbh-sukrit.answer':
    "<p><kumbh-sukrit-link>Kumbh Sukrit Camp</kumbh-sukrit-link> and camp67 come under the banner of <swt-link>Sukrit Wellness Tours</swt-link>. In short: we are a combined team across all three ventures, aimed at providing transformative wellness experiences. We have been operating in India for over 10+ years and offer a variety of stays and tours across the country for one & all.</p> <paragraph>While camp67 is a brand-new offering from our team, it carries much of the same characteristics as our prior editions of <kumbh-sukrit-link>Kumbh Sukrit Camp</kumbh-sukrit-link>. We're confident that if you've stayed with us before, you'll love the mix of familiar and new.</paragraph>",
  'camp67-faq.all-day-facilities.answer':
    "Yes! The location at which we host our Kumbh campsites are owned and managed by us even beyond Kumbh. Hence, they have all-day facilities, even if you were to come and visit us today. If there's certain facilities you're interested in, let us know.",
};

export const getInTouch = {
  'camp67-get-in-touch.success-thankyou': 'Thank you.',
  'camp67-get-in-touch.success-message': `We've received your request and will get back to you soon!`,
  'camp67-get-in-touch': 'Get in touch',
  'camp67-get-in-touch.12-hours': `Interested in the Mahakumbh Mela 2025? We're here to help! Inquire now and we'll get back to you in 12 hours.`,
  'camp67-get-in-touch.placeholder-name': 'Enter your name...',
  'camp67-get-in-touch.error-name': 'Please enter your name',
  'camp67-get-in-touch.placeholder-phone': 'Enter your phone...',
  'camp67-get-in-touch.error-phone': 'Please enter a valid phone number',
  'camp67-get-in-touch.placeholder-email': 'Enter your email...',
  'camp67-get-in-touch.error-email': 'Please enter a valid email',
  'camp67-get-in-touch.placeholder-inquiry': 'Enter your inquiry...',
  'camp67-get-in-touch.error-inquiry': 'Please enter an inquiry',
};

export const experienceKumbh = {
  'camp67-experience-kumbh.the-kumbh': 'The Kumbh',
  'camp67-experience-kumbh.engage-unique-experience':
    'Engage in the most unique<br />experience of the Kumbh Mela.',
  'camp67-experience-kumbh.sadhus':
    'From interactions with Nāga Sadhus to joining the biggest congregation of humanity and culture, experience the Kumbh Mela.',
  'camp67-experience-kumbh.sound.mobile': 'Sounds of Kumbh',
  'camp67-experience-kumbh.play-sound': 'Hear the sounds of Kumbh',
  'camp67-experience-kumbh.pause-sound': 'Pause the sounds of Kumbh',
  'camp67-experience-kumbh.naga-sadhu.image-alt': 'A Nāga Sadhu at the Kumbh Mela.',
  'camp67-experience-kumbh.naga-sadhu-orange.image-alt':
    'A Nāga Sadhu at the Kumbh Mela, dressed in orange garb.',
  'camp67-experience-kumbh.naga-sadhu-beard.image-alt':
    'A Nāga Sadhu at the Kumbh Mela, with a uniquely long beard.',
  'camp67-experience-kumbh.canoe.image-alt':
    'A man paddling a canoe in the midst of the Ganges.',
};

export const joinNow = {
  'camp67-join-now.book-now': '~ Book Now ~',
  'camp67-join-now.interested-in-kumbh':
    'Interested in Kumbh <br className="hidden sm:block" /> 2025? <join-us-text>Join Us</join-us-text>',
  'camp67-join-now.book-now-lowest-price':
    '<strong>Book a room with us →</strong><br />Starting From',
  'camp67-join-now.chat-with-us':
    '<strong>Chat with us →</strong><br />Ask us any questions',
};
